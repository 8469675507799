import styled from "styled-components";
import { ImageWrapper } from "../styles";

export const Container = styled(ImageWrapper)`
  padding-bottom: 0;
  position: relative;
  flex: 1 1 10%;

  opacity: 0;
  transform: translateY(100px);
  ${({ theme }) => theme.mediaQueries.tablet} {
    // Figma: w 720  h 1024  142%
    // This is a fallback, the value will be overridden via javascript
    padding-top: calc(1024 / 720 * 100%);
  }
  ${({ theme }) => theme.mediaQueries.laptop} {
    // Figma: w 944  h 768  81%
    // This is a fallback, the value will be overridden via javascript
    padding-top: calc(768 / 944 * 100%);
  }
  ${({ theme }) => theme.mediaQueries.desktop} {
    // Figma: w 1356  h 871  64%
    // This is a fallback, the value will be overridden via javascript
    padding-top: calc(871 / 1356 * 100%);
  }
`;
