import { imageLoader } from "@/lib/sanity/client";
import { LaptopImage, MobileImage, TabletImage } from "./styles";

type ImageProps = {
  _type: "image";
  asset: {
    url: string;
  };
};

export type ResponsiveImageProps = {
  mobile: ImageProps;
  tablet: ImageProps;
  laptop: ImageProps;
};

const ResponsiveImage: React.FC<ResponsiveImageProps> = (props) => {
  const { mobile, tablet, laptop } = props;
  const commonProps = {
    layout: "fill" as "fill",
    objectFit: "cover" as "cover",
    loader: imageLoader,
    alt: "",
    priority: true,
  };

  return (
    <>
      <MobileImage src={mobile.asset?.url} {...commonProps} />
      <TabletImage src={tablet.asset?.url} {...commonProps} />
      <LaptopImage src={laptop.asset?.url} {...commonProps} />
    </>
  );
};

export default ResponsiveImage;
