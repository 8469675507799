import { forwardRef, PropsWithChildren } from "react";
import { Container } from "./styles";

type Props = PropsWithChildren<{}>;

const Cover = forwardRef<HTMLDivElement, Props>(function Cover(props, ref) {
  const { children } = props;
  return (
    <Container ref={ref} $hasLoaded>
      {children}
    </Container>
  );
});

export default Cover;
