/* eslint-disable jsx-a11y/alt-text */
import gsap, { SplitText } from "@/lib/gsap";
import React, { useEffect, useRef } from "react";
import {
  Container,
  Content,
  Excerpt,
  HeadingContainer,
  HeadingLineOne,
  HeadingLineTwo,
} from "./styles";
import ResponsiveImage, { ResponsiveImageProps } from "./ResponsiveImage";
import Cover from "./Cover";
import Button from "@/components/Button";
import Link from "next/link";
import { SVGArrowRight } from "@/lib/svg";
import { InternalLinkProps } from "@/components/ButtonComplex/InternalLink";
import _SplitText from "gsap/SplitText";
import { debounce } from "lodash";
import { myTheme } from "@/lib/styles/theme";

export type HeroProps = {
  headingLineOne: string | null;
  headingLineTwo: string | null;
  excerpt: string | null;
  mobileImage: ResponsiveImageProps["mobile"] | null;
  tabletImage: ResponsiveImageProps["tablet"] | null;
  laptopImage: ResponsiveImageProps["laptop"] | null;
  internalLink?: InternalLinkProps;
};

const Hero: React.FC<HeroProps> = (props) => {
  const {
    headingLineOne,
    headingLineTwo,
    excerpt,
    mobileImage,
    tabletImage,
    laptopImage,
    internalLink,
  } = props;

  let url;
  if (internalLink?.page?._type == "productListingPage") {
    url = "/products";
  } else {
    url = internalLink?.page?.slug;
  }

  const containerRef = useRef<HTMLDivElement>(null);
  const headingLineOneRef = useRef<HTMLHeadingElement>(null);
  const headingLineTwoRef = useRef<HTMLHeadingElement>(null);

  const contentRef = useRef<HTMLDivElement>(null);
  const coverRef = useRef<HTMLDivElement>(null);
  const excerptRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);

  const excerptTextRef = useRef<_SplitText | null>(null);

  const adjustHeight = () => {
    // Create closure around mutable ref
    const container = containerRef.current;
    const cover = coverRef.current;
    if (window && container && cover) {
      // We are using parseFloat to get the value out of the string (which should
      //   be something like `4.5rem`) and transforming the value from rem to px.
      const pageTopSize = parseFloat(myTheme.space.headerMinHeight) * 16;
      container.style.height = `${window.innerHeight - pageTopSize}px`;
      if (window.innerWidth > parseFloat(myTheme.breakpoints.md) * 16) {
        cover.style.paddingTop = `${window.innerHeight - pageTopSize}px`;
      }
    }
  };

  useEffect(() => {
    adjustHeight();

    // Create closure around mutable ref
    const container = containerRef.current;
    const headingLineOne = headingLineOneRef.current;
    const headingLineTwo = headingLineTwoRef.current;
    const content = contentRef.current;
    const cover = coverRef.current;
    const excerpt = excerptRef.current;
    const button = buttonRef.current;

    excerptTextRef.current = new SplitText(excerpt, {
      type: "lines",
      linesClass: "lines",
    });
    const excerptText = excerptTextRef.current;

    const revertText = debounce(() => {
      if (excerptText) {
        excerptText?.revert();
      }
    }, 30);

    window.addEventListener("resize", revertText);

    gsap.set([excerpt], { opacity: 1.0 });

    const timeline = gsap
      .timeline({
        default: {
          duration: 0.3,
        },
        scrollTrigger: {
          trigger: container,
          start: "top 75%",
          toggleActions: "play pause resume reset",
        },
      })
      .to(
        cover,
        {
          opacity: 1.0,
          translateY: 0,
        },
        "<25%"
      )
      .to(content, {
        opacity: 1,
        translateY: 0,
        duration: 0.2,
      })
      .to(headingLineOne, {
        opacity: 1.0,
        translateY: 0,
        duration: 0.2,
      })
      .to(headingLineTwo, {
        opacity: 1.0,
        translateY: 0,
        duration: 0.2,
      })
      .to(
        excerptText.lines,
        {
          opacity: 1.0,
          translateY: 0,
          stagger: { amount: 0.3 },
        },
        "<25%"
      )
      .to(
        button,
        {
          opacity: 1.0,
          translateY: 0,
        },
        "<25%"
      );

    return function cleanup() {
      window.removeEventListener("resize", revertText);
      timeline.kill();
    };
  }, []);

  const responsiveImageProps = mobileImage &&
    tabletImage &&
    laptopImage && {
      mobile: mobileImage,
      tablet: tabletImage,
      laptop: laptopImage,
    };

  return (
    <Container ref={containerRef}>
      <Cover ref={coverRef}>
        {responsiveImageProps && <ResponsiveImage {...responsiveImageProps} />}
      </Cover>

      <Content ref={contentRef}>
        <HeadingContainer>
          {headingLineOne && (
            <HeadingLineOne ref={headingLineOneRef}>
              {headingLineOne}
            </HeadingLineOne>
          )}
          {headingLineTwo && (
            <HeadingLineTwo ref={headingLineTwoRef}>
              {headingLineTwo}
            </HeadingLineTwo>
          )}
        </HeadingContainer>
        {excerpt && (
          <Excerpt
            ref={excerptRef}
            dangerouslySetInnerHTML={{
              __html: excerpt.replace(/\n/g, "<br />"),
            }}
          ></Excerpt>
        )}
        {internalLink?.title && url && (
          <Link href={url} passHref>
            <Button
              ref={buttonRef}
              as="a"
              variant="primaryUnboxed"
              icon={<SVGArrowRight />}
              style={{
                width: "fit-content",
                alignSelf: "flex-start",
                padding: "0",
                paddingBottom: "9px",
                opacity: "0",
                transform: "translateY(50px)",
              }}
            >
              {internalLink?.title}
            </Button>
          </Link>
        )}
      </Content>
    </Container>
  );
};

export default Hero;
