import styled from "styled-components";
import { ImageWrapper as _ImageWrapper } from "@/lib/styles/theme";
import { H2, P } from "@/lib/styles/text";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  
  ${({ theme }) => theme.mediaQueries.tablet} {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const ImageWrapper = styled(_ImageWrapper)`
  position: relative;
  grid-column: 1 / -1;

  opacity: 0;
  transform: translateY(100px);
`;

export const Content = styled.div`
  background-color: ${({theme})=> theme.colors.white};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  position: relative;
  width: 100%;
  grid-column: 1 / -1;
  opacity: 0;
  transform: translateY(50px);
  margin-bottom: -1px;

  ${({ theme }) => theme.mediaQueries.tablet} {
    padding: 40px;
    position: absolute;
    bottom: 0;
    left: 0;
    grid-column: 1 / span 5;
  }
  ${({ theme }) => theme.mediaQueries.laptop} {
    grid-column: 1 / span 2;
  }
  ${({ theme }) => theme.mediaQueries.desktop} {
    padding-top: 84px;
  }
`;
export const HeadingContainer = styled.div``;

export const HeadingLineOne = styled(H2)`
  color: ${({ theme }) => theme.colors.black};

  opacity: 0;
  transform: translateY(50px);
`;

export const HeadingLineTwo = styled(H2)`
  color: ${({ theme }) => theme.colors.red};

  opacity: 0;
  transform: translateY(50px);
`;

export const Excerpt = styled(P)`
  opacity: 0;
  max-width: unset;
  .lines {
    opacity: 0;
    transform: translateY(50px);
  }
`;
