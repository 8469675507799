import styled from "styled-components";
import NextImage from "next/image";

export const MobileImage = styled(NextImage)`
  ${({theme})=>theme.mediaQueries.tablet} {
    display: none !important;
  }
`

export const TabletImage = styled(NextImage)`
  display: none !important;
  ${({theme})=>theme.mediaQueries.tablet} {
    display: block !important;
  }
  ${({theme})=>theme.mediaQueries.laptop} {
    display: none !important;
  }
`

export const LaptopImage = styled(NextImage)`
  display: none !important;
  ${({theme})=>theme.mediaQueries.laptop} {
    display: block !important;
  }
`
